"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = void 0;
function reducer(state, action) {
    switch (action.type) {
        case "metaMaskUnavailable":
            return {
                chainId: null,
                account: null,
                status: "unavailable",
            };
        case "metaMaskNotConnected":
            return {
                chainId: action.payload.chainId,
                account: null,
                status: "notConnected",
            };
        case "metaMaskConnected":
            var unlockedAccounts = action.payload.accounts;
            return {
                chainId: action.payload.chainId,
                account: unlockedAccounts[0],
                status: "connected",
            };
        case "metaMaskConnecting":
            if (state.status === "initializing" || state.status === "unavailable") {
                console.warn("Invalid state transition from \"".concat(state.status, "\" to \"connecting\". Please, file an issue."));
                return state;
            }
            return __assign(__assign({}, state), { account: null, status: "connecting" });
        case "metaMaskPermissionRejected":
            if (state.status === "initializing" || state.status === "unavailable") {
                console.warn("Invalid state transition from \"".concat(state.status, "\" to \"connecting\". Please, file an issue."));
                return state;
            }
            return __assign(__assign({}, state), { account: null, status: "notConnected" });
        case "metaMaskAccountsChanged":
            if (state.status !== "connected") {
                console.warn("Invalid accounts change in \"".concat(state.status, "\". Please, file an issue."));
                return state;
            }
            var accounts = action.payload;
            if (accounts.length === 0) {
                return __assign(__assign({}, state), { account: null, status: "notConnected" });
            }
            return __assign(__assign({}, state), { account: accounts[0] });
        case "metaMaskChainChanged":
            if (state.status === "initializing" || state.status === "unavailable") {
                console.warn("Invalid chain ID change in \"".concat(state.status, "\". Please, file an issue."));
                return state;
            }
            return __assign(__assign({}, state), { chainId: action.payload });
        // no default
    }
}
exports.reducer = reducer;
