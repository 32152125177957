"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaMaskProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var metamask_context_1 = require("./metamask-context");
var reducer_1 = require("./reducer");
var useSafeDispatch_1 = require("./utils/useSafeDispatch");
// MetaMask - RPC Error: Request of type 'wallet_requestPermissions' already pending for origin [origin]. Please wait.
var ERROR_CODE_REQUEST_PENDING = -32002;
function getMetaMaskProvider() {
    var ethereum = window.ethereum;
    if (!ethereum)
        return null;
    // The `providers` field is populated
    // - when CoinBase Wallet extension is also installed
    // - when user is on Brave and Brave Wallet is not deactivated
    // The expected object is an array of providers, the MetaMask provider is inside
    // See https://docs.cloud.coinbase.com/wallet-sdk/docs/injected-provider-guidance for more information
    // See also https://metamask.zendesk.com/hc/en-us/articles/360038596792-Using-Metamask-wallet-in-Brave-browser
    if (Array.isArray(ethereum.providers)) {
        var metaMaskProvider = ethereum.providers.find(function (p) { return p.isMetaMask && !p.isBraveWallet; });
        if (metaMaskProvider)
            return metaMaskProvider;
        var braveWalletProvider = ethereum.providers.find(function (p) { return p.isMetaMask && p.isBraveWallet; });
        if (!braveWalletProvider)
            return null;
        return braveWalletProvider;
    }
    if (!ethereum.isMetaMask)
        return null;
    return ethereum;
}
function getSafeMetaMaskProvider() {
    var ethereum = getMetaMaskProvider();
    if (!ethereum) {
        throw new Error("MetaMask provider must be present in order to use this method");
    }
    return ethereum;
}
function synchronize(dispatch) {
    return __awaiter(this, void 0, void 0, function () {
        var ethereum, chainId, accessibleAccounts;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ethereum = getMetaMaskProvider();
                    if (!ethereum) {
                        dispatch({ type: "metaMaskUnavailable" });
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, ethereum.request({
                            method: "eth_chainId",
                        })];
                case 1:
                    chainId = _a.sent();
                    return [4 /*yield*/, ethereum.request({
                            method: "eth_accounts",
                        })];
                case 2:
                    accessibleAccounts = _a.sent();
                    if (accessibleAccounts.length === 0) {
                        dispatch({ type: "metaMaskNotConnected", payload: { chainId: chainId } });
                    }
                    else {
                        dispatch({
                            type: "metaMaskConnected",
                            payload: { accounts: accessibleAccounts, chainId: chainId },
                        });
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function subscribeToManualConnection(dispatch) {
    var _this = this;
    var ethereum = getSafeMetaMaskProvider();
    var onAccountsChanged = function (accounts) { return __awaiter(_this, void 0, void 0, function () {
        var chainId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (accounts.length === 0)
                        return [2 /*return*/];
                    return [4 /*yield*/, ethereum.request({
                            method: "eth_chainId",
                        })];
                case 1:
                    chainId = _a.sent();
                    dispatch({
                        type: "metaMaskConnected",
                        payload: {
                            accounts: accounts,
                            chainId: chainId,
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    ethereum.on("accountsChanged", onAccountsChanged);
    return function () {
        ethereum.removeListener("accountsChanged", onAccountsChanged);
    };
}
function subsribeToAccountsChanged(dispatch) {
    var ethereum = getSafeMetaMaskProvider();
    var onAccountsChanged = function (accounts) {
        return dispatch({ type: "metaMaskAccountsChanged", payload: accounts });
    };
    ethereum.on("accountsChanged", onAccountsChanged);
    return function () {
        ethereum.removeListener("accountsChanged", onAccountsChanged);
    };
}
function subscribeToChainChanged(dispatch) {
    var ethereum = getSafeMetaMaskProvider();
    var onChainChanged = function (chainId) {
        return dispatch({ type: "metaMaskChainChanged", payload: chainId });
    };
    ethereum.on("chainChanged", onChainChanged);
    return function () {
        ethereum.removeListener("chainChanged", onChainChanged);
    };
}
function requestAccounts(dispatch) {
    var _this = this;
    var ethereum = getSafeMetaMaskProvider();
    dispatch({ type: "metaMaskConnecting" });
    /**
     * Note about the pattern
     * Instead of only relying on the RPC Request response, the resolve of the promise may happen based from a polling
     * using the eth_accounts rpc endpoint.
     * The reason for this change is in order to handle pending connection request on MetaMask side.
     * See https://github.com/VGLoic/metamask-react/issues/13 for the full discussion.
     * Any improvements on MetaMask side on this behaviour that could allow to go back to the previous, simple and safer, pattern
     * should trigger the update of this code.
     */
    return new Promise(function (resolve, reject) {
        var intervalId = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
            var accounts, chainId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ethereum.request({
                            method: "eth_accounts",
                        })];
                    case 1:
                        accounts = _a.sent();
                        if (accounts.length === 0)
                            return [2 /*return*/];
                        clearInterval(intervalId);
                        return [4 /*yield*/, ethereum.request({
                                method: "eth_chainId",
                            })];
                    case 2:
                        chainId = _a.sent();
                        dispatch({ type: "metaMaskConnected", payload: { accounts: accounts, chainId: chainId } });
                        resolve(accounts);
                        return [2 /*return*/];
                }
            });
        }); }, 200);
        ethereum
            .request({
            method: "eth_requestAccounts",
        })
            .then(function (accounts) { return __awaiter(_this, void 0, void 0, function () {
            var chainId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clearInterval(intervalId);
                        return [4 /*yield*/, ethereum.request({
                                method: "eth_chainId",
                            })];
                    case 1:
                        chainId = _a.sent();
                        dispatch({ type: "metaMaskConnected", payload: { accounts: accounts, chainId: chainId } });
                        resolve(accounts);
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function (err) {
            if ("code" in err) {
                if (err.code === ERROR_CODE_REQUEST_PENDING)
                    return;
            }
            dispatch({ type: "metaMaskPermissionRejected" });
            clearInterval(intervalId);
            reject(err);
        });
    });
}
function addEthereumChain(parameters) {
    return __awaiter(this, void 0, void 0, function () {
        var ethereum, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ethereum = getSafeMetaMaskProvider();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [parameters],
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    if ("code" in err_1) {
                        if (err_1.code === ERROR_CODE_REQUEST_PENDING)
                            return [2 /*return*/];
                    }
                    throw err_1;
                case 4: return [2 /*return*/];
            }
        });
    });
}
function switchEthereumChain(chainId) {
    return __awaiter(this, void 0, void 0, function () {
        var ethereum, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ethereum = getSafeMetaMaskProvider();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ethereum.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: chainId }],
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    if ("code" in err_2) {
                        if (err_2.code === ERROR_CODE_REQUEST_PENDING)
                            return [2 /*return*/];
                    }
                    throw err_2;
                case 4: return [2 /*return*/];
            }
        });
    });
}
var initialState = {
    status: "initializing",
    account: null,
    chainId: null,
};
function MetaMaskProvider(props) {
    var _a = React.useReducer(reducer_1.reducer, initialState), state = _a[0], unsafeDispatch = _a[1];
    var dispatch = (0, useSafeDispatch_1.useSafeDispatch)(unsafeDispatch);
    var status = state.status;
    var isInitializing = status === "initializing";
    React.useEffect(function () {
        if (isInitializing) {
            synchronize(dispatch);
        }
    }, [dispatch, isInitializing]);
    var isConnected = status === "connected";
    React.useEffect(function () {
        if (!isConnected)
            return function () { };
        var unsubscribe = subsribeToAccountsChanged(dispatch);
        return unsubscribe;
    }, [dispatch, isConnected]);
    var isAvailable = status !== "unavailable" && status !== "initializing";
    React.useEffect(function () {
        if (!isAvailable)
            return function () { };
        var unsubscribe = subscribeToChainChanged(dispatch);
        return unsubscribe;
    }, [dispatch, isAvailable]);
    var isAvailableAndNotConnected = status === "notConnected";
    React.useEffect(function () {
        if (!isAvailableAndNotConnected)
            return function () { };
        var unsubscribe = subscribeToManualConnection(dispatch);
        return unsubscribe;
    }, [dispatch, isAvailableAndNotConnected]);
    var connect = React.useCallback(function () {
        if (!isAvailable) {
            console.warn("`enable` method has been called while MetaMask is not available or synchronising. Nothing will be done in this case.");
            return Promise.resolve([]);
        }
        return requestAccounts(dispatch);
    }, [dispatch, isAvailable]);
    var addChain = React.useCallback(function (parameters) {
        if (!isAvailable) {
            console.warn("`addChain` method has been called while MetaMask is not available or synchronising. Nothing will be done in this case.");
            return Promise.resolve();
        }
        return addEthereumChain(parameters);
    }, [isAvailable]);
    var switchChain = React.useCallback(function (chainId) {
        if (!isAvailable) {
            console.warn("`switchChain` method has been called while MetaMask is not available or synchronising. Nothing will be done in this case.");
            return Promise.resolve();
        }
        return switchEthereumChain(chainId);
    }, [isAvailable]);
    var value = React.useMemo(function () { return (__assign(__assign({}, state), { connect: connect, addChain: addChain, switchChain: switchChain, ethereum: isAvailable ? getSafeMetaMaskProvider() : null })); }, [connect, addChain, switchChain, state, isAvailable]);
    return (0, jsx_runtime_1.jsx)(metamask_context_1.MetamaskContext.Provider, __assign({ value: value }, props));
}
exports.MetaMaskProvider = MetaMaskProvider;
